import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import ImageLoader from '../../../components/ImageLoader';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { UserContext } from '../../../context/UserProvider';
import { show, update as updateDropoff } from '../../../services/dropoff';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { update, remove } from '../../../services/user';

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const thStyle = {
  border: '1px solid black',
  textAlign: 'left',
  padding: '8px',
  backgroundColor: '#f2f2f2',
};

const tdStyle = {
  border: '1px solid black',
  textAlign: 'left',
  padding: '8px',
};

const App = () => {
  const navigate = useNavigate();
  const { token, userAttr } = useContext(UserContext);
  const [data, setData] = useState(null);
  let { id } = useParams();

  const loadData = payload => {
    show(token, id).then(res => {
      setData(res);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const RowEntry = ({ label, value }) => {
    return (
      <tr>
        <td style={thStyle}>{label}</td>
        <td style={tdStyle}>{value}</td>
      </tr>
    );
  };

  const activateUser = () => {
    let confirm = window.confirm(
      'Are you sure you want to activate this user?',
    );

    if (!confirm) {
      return;
    }

    update(token, { status: 'active' }, id).then(res => {
      loadData();
    });
  };

  const handelDelete = () => {
    let confirm = window.confirm('Are you sure you want to delete this user?');

    if (!confirm) {
      return;
    }

    remove(token, id).then(res => {
      navigate('/admin/dropoff-partners');
    });
  };

  const changeType = (typeVal) => {
    let confirm = window.confirm('Are you sure you want to change this account type?');

    if (!confirm) {
      return;
    }

    updateDropoff(token, { type: typeVal }, data.user['info-id']).then(res => {
      loadData();
    });
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={4} style={{ height: '100px' }}>
            <h1>Dropoff Information</h1>
          </Grid>
          <Grid item xs={12} sm={8} style={{ height: '100px' }}>
            {data.user['status'] === 'pending' && (
              <>
                <Button
                  variant="contained"
                  onClick={() => activateUser()}
                  style={
                    isMobile
                      ? {}
                      : {
                          width: '160px',
                          float: 'right',
                          marginTop: '20px',
                          marginLeft: '20px',
                        }
                  }>
                  Activate
                </Button>
              </>
            )}

            {data.user['status'] === 'active' && (
              <>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate('/admin/wallet/dropoff-partners/' + id)
                  }
                  style={
                    isMobile
                      ? {}
                      : {
                          width: '160px',
                          float: 'right',
                          marginTop: '20px',
                          marginLeft: '20px',
                        }
                  }>
                  Wallet
                </Button>

                {data.dropoff['type'] == 'sub' ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => changeType('main')}
                      style={
                        isMobile
                          ? {}
                          : {
                              width: '160px',
                              float: 'right',
                              marginTop: '20px',
                              marginLeft: '20px',
                            }
                      }>
                      Make Main
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => changeType('sub')}
                      style={
                        isMobile
                          ? {}
                          : {
                              width: '160px',
                              float: 'right',
                              marginTop: '20px',
                              marginLeft: '20px',
                            }
                      }>
                      Make Sub
                    </Button>
                  </>
                )}
              </>
            )}

            <Button
              variant="contained"
              onClick={() => handelDelete()}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginLeft: '20px',
                      marginTop: '20px',
                    }
              }>
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/admin/dropoff-partners')}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginTop: '20px',
                    }
              }>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <table style={tableStyle}>
              <tbody>
                <tr>
                  <td
                    style={{
                      ...thStyle,
                      fontWeight: 'bold',
                      fontSize: '20px',
                      textAlign: 'center',
                      backgroundColor: '#dedff4',
                    }}
                    colSpan={2}>
                    Account Information
                  </td>
                </tr>
                <RowEntry
                  label="Account Status"
                  value={toTitleCase(data.user['status'])}
                />
                <RowEntry
                  label="Account Type"
                  value={
                    data.dropoff['type'] == 'sub'
                      ? 'Sub Dropoff'
                      : 'Main Dropoff'
                  }
                />
                <RowEntry label="Mobile Number" value={data.user['login']} />
                <RowEntry
                  label="Name"
                  value={`${data.dropoff['first-name']} ${data.dropoff['middle-name']} ${data.dropoff['last-name']}`}
                />
                <RowEntry
                  label="Birth Date"
                  value={data.dropoff['birth-date']}
                />
                <RowEntry
                  label="Address Line 1"
                  value={data.dropoff['address-line-1']}
                />
                <RowEntry
                  label="Address Line 2"
                  value={data.dropoff['address-line-2']}
                />
                <RowEntry label="City" value={data.dropoff['address-city']} />
                <RowEntry
                  label="Province"
                  value={data.dropoff['address-province']}
                />
                <RowEntry
                  label="Zip Code"
                  value={data.dropoff['address-zip-code']}
                />
                <RowEntry
                  label="Email Address"
                  value={data.dropoff['email-address']}
                />
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
