import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Card, Grid, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { getAccountInformation } from '../../../services/user';
import { getMyList } from '../../../services/affiliate';
import { submitAffiliateValidIdentification } from '../../../services/customer';
import ImageUploader from '../../../components/ImageUploader';
import LoadingScreen from '../../../components/LoadingScreen';
import ShareLink from '../../../components/ShareLink/index.jsx';
import { toTitleCase } from '../../../utils/helpers';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

// import { status as sameDayStatus } from '../../../config/sameDayStatus';
// import { renderStatusLabel } from '../../../config/regularStatus';
// import { styles } from '../../../config/defaultPageStyles';
// import { moneyFormat } from '../../../utils/helpers';


const CustomerAffiliationPage = () => {
  const navigate = useNavigate();
  const { token, logout } = useContext(UserContext);
  //   const timeAgo = new TimeAgo('en-US');
  const [isAffiliate, setIsAffiliated] = useState(null);
  const [submittedId, setSubmittedId] = useState(null);
  const [affiliateCode, setAffiliateCode] = useState(null);
  const [showOtherUsers, setShowOtherUsers] = useState(false);

  useEffect(() => {
    loadUserInformation();
  }, []);

  const loadUserInformation = () => {
    getAccountInformation(token)
      .then(response => {
        setIsAffiliated(
          response?.customer?.type === 'Affiliated' ? true : false,
        );
        setSubmittedId(
          response?.customer['affiliate-valid-identification-card-file-id']
            ? true
            : false,
        );
        setAffiliateCode(response?.customer['affiliate-code']);
      })
      .catch(error => {
        console.log(error);
      });
  };

  if (isAffiliate === null) {
    return <LoadingScreen />;
  }

  return (
    <Grid container spacing={2} layout={'row'}>
      {!isAffiliate && !submittedId && <AffiliateForm token={token} />}
      {!isAffiliate && submittedId && (
        <>
          <Grid
            item
            xs={12}
            height={'auto'}
            style={{ marginTop: '20px', textAlign: 'center' }}>
            <h1>Thank you for your submission</h1>
            <img src="/img/review.gif" style={{ width: '100%' }} />
            <p>We are still verifying your ID. Please check back later.</p>
          </Grid>
        </>
      )}

      {!showOtherUsers ? (
        <>
          {isAffiliate && (
            <AffiliateInformation
              affiliateCode={affiliateCode}
              setShowOtherUsers={setShowOtherUsers}
            />
          )}
        </>
      ) : (
        <>
          {isAffiliate && (
            <UsersList
              affiliateCode={affiliateCode}
              setShowOtherUsers={setShowOtherUsers}
            />
          )}
        </>
      )}

      <Grid item xs={12} height={'auto'}></Grid>
      <Grid item xs={12} height={'auto'}>
        <Button onClick={() => navigate(-1)} variant="contained" fullWidth>
          Back
        </Button>
      </Grid>
    </Grid>
  );
};

const AffiliateForm = ({ token }) => {
  const photoList = useRef([]);

  const submit = () => {
    if (photoList.current.length < 1) {
      return alert('Please upload photo');
    }

    submitAffiliateValidIdentification(token, photoList.current[0].id).then(
      response => {
        window.location.reload();
      },
    );
  };

  return (
    <>
      <Grid item xs={12} height={'auto'} style={{ marginTop: '20px' }}>
        <Typography variant="h5">Affiliate Form</Typography>
        <p>
          Before you can get your affiliation code you need to submit valid
          Government ID for verification
        </p>
      </Grid>
      <Grid item xs={12} height={'auto'} style={{ marginTop: '0px' }}>
        <ImageUploader
          name={'affiliationIDPhoto'}
          type={'Affiliation ID Photo'}
          title={'Upload Valid ID Photo'}
          label={''}
          ref={photoList}
          defaultImage={null}
        />
      </Grid>
      <Grid item xs={12} height={'auto'}>
        <Button onClick={() => submit()} variant="contained" fullWidth>
          Submit
        </Button>
      </Grid>
    </>
  );
};

const AffiliateInformation = ({ affiliateCode, setShowOtherUsers }) => {
  return (
    <>
      <Grid
        item
        xs={12}
        height={'auto'}
        style={{ marginTop: '20px', textAlign: 'center' }}>
        <h1>Affiliate Information</h1>
        <p>
          You are now an affiliate. You can now share your affiliate code to
          your friends and earn commission.
        </p>
        <p>
          Affiliate Code: <b>{affiliateCode}</b>
        </p>
        <ShareLink
          url={`${process.env.REACT_APP_URL}/customer/signup/${affiliateCode}`}
        />

        <Button
          onClick={() => setShowOtherUsers(true)}
          variant="contained"
          fullWidth>
          View My Affiliates
        </Button>
      </Grid>
    </>
  );
};

const UsersList = ({ setShowOtherUsers }) => {
  const [userList, setUserList] = useState(null);
  const { token } = useContext(UserContext);
  const timeAgo = new TimeAgo('en-US');

  useEffect(() => {
    const loadMyAffiliates = () => {
      getMyList(token)
        .then(response => {
          setUserList(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    };

    loadMyAffiliates();
  }, []);

  useEffect(() => {
    console.log('userList', userList);
  }, [userList]);

  const UserList = () => {
    const summary = [];
    userList.forEach((user, key) => {
      // push new dom
      summary.push(
        <div
          key={key}
          style={{
            float: 'left',
            textAlign: 'left',
            borderBottom: 'solid 1px',
            marginBottom: '10px',
            width: '100%'
          }}
        >
          <p style={{marginBottom: 0}}>
            <strong> Name: </strong> {toTitleCase(user.summary['first-name'])}{' '}
            {toTitleCase(user.summary['last-name'])}
          </p>
          <p style={{marginTop: 0}}>
            <small>
              <strong> Join Last: </strong> {timeAgo.format(new Date(user.created_last))}
            </small>
          </p>
        </div>,
      );
    });

    return <div>{summary}</div>;
  };

  return (
    <>
      <Grid
        item
        xs={12}
        height={'auto'}
        style={{ marginTop: '20px', textAlign: 'center' }}>
        <h1>My Affiliates</h1>
        {userList && <UserList />}
        <Button
          onClick={() => setShowOtherUsers(false)}
          variant="contained"
          fullWidth>
          View affiliate code
        </Button>
      </Grid>
    </>
  );
};

export default CustomerAffiliationPage;
