import React from 'react';
import RecoveryForm from '../../../components/LoginForm/recoverForm';

const RecoverForm = () => {
  return (
    <RecoveryForm nextPage={'/customer'} userType={'customer'} allowEmailOtp={true} />
  );
};

export default RecoverForm;
