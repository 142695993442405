import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../../../components/LoadingScreen';
import { UserContext } from '../../../../context/UserProvider';
import { showRegular as show, updateRegular } from '../../../../services/order';
import { Button } from '@mui/material';
import { moneyFormat, toTitleCase } from '../../../../utils/helpers';
import { status as regularStatus } from '../../../../config/regularStatus';
import { show as showFile } from '../../../../services/file';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import QRCode from "react-qr-code";

const styles = {
  sectionContatiner: {
    textAlign: 'left',
    backgroundColor: '#dedede',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    fontSize: '15px',
    color: '#494949',
  },
  sectionContatinerDriver: {
    textAlign: 'left',
    backgroundColor: '#ffff',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  sectionHeader: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionData: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionDataTo: {
    fontWeight: 'bold',
  },
  sectionButton: {
    marginTop: '10px',
  },
  packageImage: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
  },
};

let regularLabels = {
  door2door: 'Door 2 Door',
  dropoff2dropoff: 'Dropoff 2 Dropoff',
  dropoff2door: 'Dropoff 2 Door',
  door2dropoff: 'Door 2 Dropoff',
};

const App = () => {
  const { token, userType } = useContext(UserContext);
  const { id } = useParams();
  const [status, setStatus] = useState(null);
  const [jobOrderInformation, setJobOrderInformation] = useState(null);
  const [showInformation, setShowInformation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadOrder();
  }, []);

  useEffect(() => {
    if (status === 'active' && jobOrderInformation?.type !== 'dropoff2dropoff' && jobOrderInformation?.type !== 'dropoff2door') {
      const interval = setInterval(() => {
        monitorStatus();
      }, 3000);
      return () => clearInterval(interval);
    } else if (jobOrderInformation?.type) {
      setShowInformation(true);
    }
  }, [status]);

  const loadOrder = () => {
    show(token, id).then(res => {
      setStatus(res.data.status);
      setJobOrderInformation(res.data);
    });
  };

  const monitorStatus = () => {
    show(token, id).then(res => {
      if (res.data.status !== 'active') {
        setShowInformation(true);
        setStatus(res.data.status);
        setJobOrderInformation(res.data);
      }
    });
  };

  const cancelHandler = () => {
    const confirm = window.confirm('Are you sure you want to cancel?');
    if (confirm) {
      updateRegular(token, { status: 'canceled' }, id).then(res => {
        navigate('/customer');
      });
    }
  };

  if (!status) {
    return <LoadingScreen />;
  } else if (!showInformation) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1>Please Wait</h1>
        <img
          src="/img/looking-for-driver.gif"
          style={{ width: '100%', marginBottom: '30px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/customer')}>
          GO TO DASHBOARD
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowInformation(true)}
          style={{ marginTop: '10px' }}>
          VIEW ORDER INFORMATION
        </Button>

        {(status === 'active' || status === 'waiting for payment') && (
          <Button
            variant="outlined"
            fullWidth
            color="error"
            style={{ marginTop: '10px' }}
            onClick={() => cancelHandler()}>
            CANCEL
          </Button>
        )}
      </div>
    );
  } else {
    const data = jobOrderInformation.data ?? {};
    console.log('data', data);
    return (
      <>
        <div
          style={{
            textAlign: 'center',
            fontSize: '1.2rem',
            paddingTop: '35px',
            fontWeight: 'bold',
          }}>
          Order Information <br />
          <small style={{ fontSize: '12px' }}>
            ORDER # {jobOrderInformation['reference-number'] ?? ''}
          </small>
          <br />
          <small style={{ fontSize: '12px' }}>
            {regularLabels[data?.type] ?? ''}
          </small>
        </div>
        <div
          style={{
            textAlign: 'center',
            fontSize: '0.9rem',
            paddingTop: '10px',
            paddingBottom: '10px',
            background: '#bec6e2',
            fontWeight: 'bold',
          }}>
          {
            status == 'active' && (
              jobOrderInformation?.type == 'dropoff2dropoff' 
            ||jobOrderInformation?.type === 'dropoff2door'
            ) 
              ? 'Please bring the package to the dropoff location' 
              : (regularStatus[status] ?? status) 
          }
        </div>
        <div
          style={{
            textAlign: 'center',
            fontSize: '1.2rem',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}>
          
          <QRCodeContainer data={data} id={id} />

          {data.dropoffOrigin && (
          <DropoffContainer
            title={'Dropoff Location for Origin'}
            data={data.dropoffOrigin}
          />)}
          {data.dropoffDestination && (
          <DropoffContainer
            title={'Dropoff Location for Destination'}
            data={data.dropoffDestination}
          />)}
          <AddressContainer title={data.dropoffOrigin ? 'Sender Details' : 'Origin Location'} data={data.sender} />
          <AddressContainer title={data.dropoffDestination ? 'Reciever Details' : 'Destination Location'} data={data.receiver} />
          <PackageContainer data={data.package} />
          <PricingContainer data={data.pricing} paymentType={jobOrderInformation['payment-type']} />
        </div>
        <div style={{ width: '100%', paddingBottom: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100%', marginTop: '15px' }}
            onClick={() => {
              navigate('/customer/dashboard');
            }}>
            Back
          </Button>
          {(status === 'active' || status === 'waiting for payment') && (
            <Button
              variant="outlined"
              fullWidth
              color="error"
              style={{ marginTop: '10px' }}
              onClick={() => cancelHandler()}>
              CANCEL
            </Button>
          )}
        </div>
      </>
    );
  }
};

export default App;

const AddressContainer = ({ title, data }) => {
  const [show, setShow] = useState(false);

  const onClick = () => {
    setShow(!show);
  };

  const label = show ? 'Hide' : 'View';

  return (
    <>
      <div style={styles.sectionContatiner}>
        <div style={styles.sectionHeader}>
          <strong>{title}</strong>
        </div>
        {!show ? (
          <>
            <p style={styles.sectionData}>
              {data?.addressLine1} {data?.addressLine2 || ''} {data.city}{' '}
              {data.province} {data.zipCode}
            </p>
          </>
        ) : (
          <>
            <table>
              <tbody>
                <tr>
                  <td style={styles.sectionDataTo}>Name: </td>
                  <td>{data?.name || ''}</td>
                </tr>
                <tr>
                  <td style={styles.sectionDataTo}>Phone: </td>
                  <td>{data?.contactNumber || ''}</td>
                </tr>
                <tr>
                  <td style={styles.sectionDataTo}>Address: </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {data?.addressLine1 || ''} {data?.addressLine1 || ''}{' '}
                    {data?.city || ''} {data?.province || ''}{' '}
                    {data?.zipCode || ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        <Button
          onClick={onClick}
          variant="outlined"
          color="primary"
          size={'small'}
          fullWidth
          style={styles.sectionButton}>
          {label}
        </Button>
      </div>
    </>
  );
};

const DropoffContainer = ({ title, data }) => {
  return (
    <>
      <div style={styles.sectionContatiner}>
        <div style={styles.sectionHeader}>
          <strong>{title}</strong>
        </div>
        <>
          <p style={styles.sectionData}>
            {data?.info?.addressLine1} {data?.info?.addressLine2 || ''}{' '}
            {data?.info.city} {data?.info.province} {data?.info.zipCode}
          </p>
        </>
      </div>
    </>
  );
};

const PackageContainer = ({ data }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadFile();
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={styles.sectionContatiner}>
      <p style={styles.sectionHeader}>
        <strong>Package</strong>
      </p>
      {url && (
        <p style={styles.sectionData}>
          <img src={url} style={styles.packageImage} />
        </p>
      )}
      <p style={styles.sectionData}>
        <strong>Package Description: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
      <p style={styles.sectionData}>
        <strong>Package Weight: </strong>
        <br />
        {data?.packageWeight} KG
      </p>
    </div>
  );
};

const DriverContainer = ({ data, showMap, status }) => {
  return (
    <div style={styles.sectionContatinerDriver}>
      <p style={styles.sectionHeader}>
        <strong>Your Driver</strong>
      </p>
      <p style={styles.sectionData}>Juan Delacurz</p>
      <p style={styles.sectionData}>09999999999</p>
      {!['completed'].includes(status) && (
        <>
          <Button
            variant="contained"
            color="primary"
            size={'small'}
            fullWidth
            style={styles.sectionButton}
            startIcon={<CallIcon />}
            href="tel:09999999999">
            Call
          </Button>
          <Button
            variant="contained"
            color="primary"
            size={'small'}
            fullWidth
            style={styles.sectionButton}
            startIcon={<LocationOnIcon />}
            onClick={() => showMap()}>
            View Location
          </Button>
        </>
      )}
    </div>
  );
};

const PricingContainer = ({ data, paymentType }) => {
  const RenderCod = () => {
    if (!data.totalCodAmount || data.totalCodAmount == 0) {
      return null;
    }

    return (
      <>
        <div>
          COD Amount:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalCodAmount || 0)}
          </span>
        </div>
      </>
    );
  };

  return (
    <div style={styles.sectionContatiner}>
       <div>
        Payment Type:{' '}
        <span style={{ float: 'right' }}>
          {toTitleCase(paymentType || '')}
        </span>
      </div>
      <div>
        Sub Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.subTotal || 0)}{' '}
        </span>
      </div>
      <RenderCod />
      <div style={{ fontSize: '25px' }}>
        Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.overallTotal || 0)}
        </span>
      </div>
    </div>
  );
};

const QRCodeContainer = ({id, data}) => {
  let codeData = '';

  if (data?.data?.verification) {
    codeData = data?.data;
    codeData = `${codeData?.verification}:${codeData?.['reference-number']}:${id}`;
  } else { 
    codeData = `${data?.verification}:${data?.['reference-number']}:${id}`;
  }

  return <>
    <div style={{ background: 'white', padding: '16px', textAlign: 'center'}}>
      <QRCode value={codeData} />
      <div>{data?.verification}</div>
    </div>
  </>
};
