import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { list } from '../../../services/wallet';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

TimeAgo.addDefaultLocale(en);

const columns = isMobile
  ? [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'date', headerName: 'Transaction Date', width: 270 },
      { field: 'type', headerName: 'Transaction Type', width: 270 },
      { field: 'debit', headerName: 'Debit Amount', width: 270 },
      { field: 'credit', headerName: 'Credit Amount', width: 270 },
      { field: 'balance', headerName: 'Balance', width: 270 },
      { field: 'remarks', headerName: 'Remarks', width: 270 },
    ]
  : [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'date', headerName: 'Transaction Date', width: 130 },
      { field: 'type', headerName: 'Transaction Type', flex: 1, renderCell: params => <CellWithShowMore {...params} limit={40} /> },
      { field: 'debit', headerName: 'Debit Amount', width: 130 },
      { field: 'credit', headerName: 'Credit Amount', width: 130},
      { field: 'balance', headerName: 'Balance', width: 130 },
      {
        field: 'transactionId',
        headerName: 'Transaction ID',
        flex: 1
      },
      {
        field: 'remarks',
        headerName: 'Remarks',
        flex: 1,
        renderCell: params => <CellWithShowMore {...params} />,
      },
    ];

function CellWithShowMore({ value, limit = 0 }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const textLimit = limit; // Set the character limit for truncation

  return (
    <div>
      {value.length <= textLimit ? (
        value
      ) : (
        <>
          {value.substring(0, textLimit)}
          <button
            onClick={handleClickOpen}
            style={{
              background: 'none',
              border: 'none',
              color: 'blue',
              cursor: 'pointer',
            }}>
            {limit > 0 ? '...' : 'VIEW' }
          </button>
        </>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Full Description</DialogTitle>
        <DialogContent>
          <DialogContentText>{value}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const App = () => {
  const navigate = useNavigate();
  const { token, userAttr } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0);
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0);
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);
  let { id, type } = useParams();
  const timeAgo = new TimeAgo('en-US');

  const updatePageHandler = pageInfo => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    });
  };

  const loadData = payload => {
    setLoading(true);
    list(token, {
      ...payload,
      fields: {
        'user-id': id,
      },
      'operator': '=',
      'user-specific': false,
    }).then(res => {
      const summary = [];
      res.collection.forEach(item => {
        const typeVar = item.data.data.description ?? item.data.processor;
        const transactionId = typeVar.match(/RD-\d{10}-\w{5}/) ?? typeVar.match(/SDD-\d{10}-\w{5}/);

        summary.push({
          id: item.raw.id,
          date: timeAgo.format(new Date(item.raw.created_last)),
          type: typeVar,
          debit: `- ${moneyFormat(item.data.debit)}`,
          credit: `+ ${moneyFormat(item.data.credit)}`,
          balance: moneyFormat(item.data.balance),
          remarks: item.data.data.remarks ?? '',
          transactionId: transactionId,
          ...item,
        });
      });

      setRows(summary);

      setPageInfoCurrentPage(res['current-page']);
      setPageInfoRowPerPage(res['record-per-page']);
      setPageInfoTotalRows(res['data-total']);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 25,
    });
  }, [pending]);

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <h1>Wallet Information</h1>
          </Grid>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <Button
              variant="contained"
              onClick={() =>
                navigate(`/admin/wallet/transaction/${type}/${id}`)
              }
              style={
                isMobile
                  ? {}
                  : {
                      width: '260px',
                      float: 'right',
                      marginTop: '20px',
                      marginLeft: '20px',
                    }
              }>
              Create Transaction
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginTop: '20px',
                    }
              }>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    transactionId: false,
                  },
                },
                pagination: {
                  paginationModel: {
                    pageSize: pageInfoRowPerPage,
                    page: pageInfoCurrentPage,
                  },
                },
              }}
              pagination
              paginationMode="server"
              rowCount={pageInfoTotalRows}
              loading={loading}
              onPaginationModelChange={newPage => updatePageHandler(newPage)}
              disableColumnFilter
              disableColumnMenu={false}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
