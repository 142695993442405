import React, { useState, useRef, useContext, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import ImageUploader from '../../../components/ImageUploader';
import { updateRegular, showRegular } from '../../../services/order';
import { UserContext } from '../../../context/UserProvider';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from '../../../components/DesktopNavbar/index.jsx';
import { mainMenu } from '../../../config/dropoffMenu.jsx';

const App = () => {
  let { id, code } = useParams();
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const photoList = useRef([]);
  const description = useRef(null);
  const [order, setOrder] = useState(null);

  const submit = () => {
    if (photoList.current.length < 1) {
      return alert('Please upload photo');
    } else if (!description.current.value || description.current.value === '') {
      return alert('Please enter delivery note');
    }

    updateRegular(
      token,
      {
        data: {
          ...order.data,
          'proof-of-pickup': {
            packagePhoto: photoList.current[0].id,
            packageDescription: description.current.value,
            dateTimeCompleted: new Date().toISOString(),
          },
        },
        status: 'completed',
      },
      id,
    ).then(res => {
      console.log(res);
      if (res && res.message === 'Record Updated') {
        alert('Order Pickup Successful');
        navigate(`/dropoff/scan/${id}/${order.data.verification}}`);
      } else {
        alert('Something went wrong');
      }
    });
  };

  const backHandler = () => {

  };

  const loadOrderData = () => {
    showRegular(token, id).then(res => {
      if (res && res.data) {
        setOrder(res.data);
      }
    });
  };

  useEffect(() => {
    loadOrderData();
  }, []);


  if (!order) {
    return null;
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'sm'}>
        <div
          style={{
            textAlign: 'center',
            fontSize: '1.2rem',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}>
          PROOF OF PICKUP
        </div>
        <div
          style={{
            textAlign: 'center',
            fontSize: '1.2rem',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}>
          <ImageUploader
            name={'packagePhoto'}
            type={'Package Photo'}
            title={'Please Upload Identification Card Image'}
            label={''}
            ref={photoList}
            defaultImage={
              photoList.current[0] ? photoList.current[0].url : null
            }
          />
          <TextField
            label={'Pickup Note'}
            variant="outlined"
            type={'text'}
            multiline
            rows={4}
            fullWidth
            inputRef={description}
          />
        </div>
        <div style={{ width: '100%', paddingBottom: '15px' }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => submit()}
            style={{ marginBottom: '10px' }}
          >
            SUBMIT
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => backHandler()}>
            Back
          </Button>
        </div>
      </Container>
    </>
  );
};

export default App;
