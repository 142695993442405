import React from 'react';
import RecoveryForm from '../../../components/LoginForm/recoverForm';

const RecoverForm = () => {
  return (
    <RecoveryForm nextPage={'/dropoff/login'} userType={'dropoff'} allowEmailOtp={true} />
  );
};

export default RecoverForm;
