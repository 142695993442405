import React, { useContext, useState } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { UserContext } from '../../../context/UserProvider.jsx';
import { get } from '../../../services/report';
import { Card, Typography, Box, Avatar } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PeopleIcon from '@mui/icons-material/People';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import PlaceIcon from '@mui/icons-material/Place';

const App = () => {
  const { token } = useContext(UserContext);
  const [report, setReport] = useState(null);

  const loadReport = () => {
    get(token, 'total-report')
      .then(response => {
        setReport(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useState(() => {
    loadReport();
  }, []);

  const ReportContainer = () => {
    if (!report) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <TrafficCard
          data={report}
          keyReport={'ActiveSameDay'}
          color={'#ffc5c5'}
          icon={<DeliveryDiningIcon />}
          iconColor={'#000000'}
        />
         <TrafficCard
          data={report}
          keyReport={'LookingForDriverSameDay'}
          color={'#ff9d9d'}
          icon={<DeliveryDiningIcon />}
          iconColor={'#000000'}
        />
        <TrafficCard
          data={report}
          keyReport={'CompletedSameDay'}
          color={'#ff7878'}
          icon={<DeliveryDiningIcon />}
          iconColor={'#000000'}
        />
         <TrafficCard
          data={report}
          keyReport={'TotalSameDay'}
          color={'#ff3737'}
          icon={<DeliveryDiningIcon />}
          iconColor={'#000000'}
        />
        <TrafficCard
          data={report}
          keyReport={'ActiveRegularOrder'}
          color={'#d4d7f6'}
          icon={<LocalShippingIcon />}
          iconColor={'#000000'}
        />
        <TrafficCard
          data={report}
          keyReport={'LookingForDriverRegularOrder'}
          color={'#a5acf7'}
          icon={<LocalShippingIcon />}
          iconColor={'#000000'}
        />
        <TrafficCard
          data={report}
          keyReport={'InDropoffRegularOrder'}
          color={'#7d88fd'}
          icon={<LocalShippingIcon />}
          iconColor={'#000000'}
        />
        <TrafficCard
          data={report}
          keyReport={'InTransferRegularOrder'}
          color={'#7075ac'}
          icon={<LocalShippingIcon />}
          iconColor={'#000000'}
        />
        <TrafficCard
          data={report}
          keyReport={'TotalRegularOrder'}
          color={'#4555ff'}
          icon={<LocalShippingIcon />}
          iconColor={'#000000'}
        />
         <TrafficCard
          data={report}
          keyReport={'TotalCustomer'}
          color={'#abfff9'}
          icon={<PeopleIcon />}
          iconColor={'#000000'}
        />
         <TrafficCard
          data={report}
          keyReport={'TotalDriver'}
          color={'#abffdc'}
          icon={<AirlineSeatReclineNormalIcon />}
          iconColor={'#000000'}
        />
         <TrafficCard
          data={report}
          keyReport={'TotalDropoff'}
          color={'#54e5a9'}
          icon={<PlaceIcon />}
          iconColor={'#000000'}
        />
      </div>
    );
  };

  return (
    <>
      <Navbar pages={mainMenu} />
      <div
        style={{
          margin: '10px',
          textAlign: 'center',
        }}>
        <WarningBox title="Server Rental Notice" message="We noticed your account has an outstanding balance that’s been overdue for 90 days. To ensure uninterrupted service, please make the necessary settlement before November 15, 2024. If settlement isn’t received by then, you’ll have an additional 15 days to back up your data before the server is permanently disabled. If you have any questions or need assistance, please don’t hesitate to reach out—we’re here to help." />
        <h1>Welcome To Boxtrack</h1>
        <ReportContainer />
      </div>
    </>
  );
};

export default App;

const TrafficCard = ({ data, keyReport, icon, color, iconColor }) => {
  console.log(data);
  if (!data[keyReport]) {
    return null;
  }

  return (
    <div style={{
      float: 'left',
      padding: '10px',
      width: '21%',
    }}>
      <Card
        sx={{
          padding: 2,
          backgroundColor: color,
          borderRadius: '16px',
          maxWidth: 250,
        }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="body2" color="textSecondary">
              {data[keyReport].label.toUpperCase()}
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              {data[keyReport].value.toLocaleString()}
            </Typography>
          </Box>
          <Avatar sx={{ backgroundColor: iconColor }}>{icon}</Avatar>
        </Box>
      </Card>
    </div>
  );
};


const WarningBox = ({ title, message }) => {
  return (
    <div
      style={{
        padding: '10px',
        backgroundColor: '#ffcccc',
        borderRadius: '10px',
        textAlign: 'center',
        marginTop: '10px',
        color: '#000000',
      }}>
      <h3 style={{
        color: '#000000',
      }}>{title}</h3>
      {message}
    </div>
  );
}