import React, { useState, useContext, useEffect } from 'react';
import { getAccountBalanceHistory } from '../../../services/user';
import { moneyFormat } from '../../../utils/helpers';
import { UserContext } from '../../../context/UserProvider';
import { styles } from '../../../config/defaultPageStyles';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  TextField,
} from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import { Link, useNavigate } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { list, listRegular } from '../../../services/order';
import { toTitleCase } from '../../../utils/helpers';
import { status as sameDayStatus } from '../../../config/sameDayStatus';
import { renderStatusLabel } from '../../../config/regularStatus';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const App = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  return (
    <div
      style={{
        ...styles.pageContainer,
        minHeight: '100vh',
        height: 'auto',
      }}>
      <div style={styles.pageHeader}>Order History</div>
      <div style={{ marginTop: '20px' }}>
        <Box>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              console.log(newValue);
              setValue(newValue);
            }}>
            <BottomNavigationAction
              label="Same Day"
              icon={<DeliveryDiningIcon />}
            />
            <BottomNavigationAction
              label="Regular"
              icon={<LocalShippingIcon />}
            />
          </BottomNavigation>
        </Box>
        {value === 0 ? <SameDayOrder /> : <RegularOrder />}
      </div>
      <Button
        onClick={() => navigate(-1)}
        variant="contained"
        fullWidth
        style={{ marginTop: '20px', float: 'left', marginBottom: '30px' }}>
        BACK
      </Button>
    </div>
  );
};

export default App;

const SameDayOrder = () => {
  const timeAgo = new TimeAgo('en-US');
  const [orders, setOrders] = useState(null);
  const { token } = useContext(UserContext);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const navigate = useNavigate();

  const loadSameDayOrders = () => {
    list(token, {
      'current-page': nextPage,
      'record-per-page': 25,
      fields: {
        status: ['draft'],
      },
      operator: '!=',
    }).then(res => {
      if (!orders) {
        setOrders(res.collection);
      } else {
        setOrders([...orders, ...res.collection]);
      }

      let currentPage = res['current-page'] ?? 1;
      let lastPage = res['last-page'] ?? 1;
      setNextPage(currentPage + 1);
      setHasNextPage(lastPage > currentPage);
    });
  };

  useEffect(() => {
    loadSameDayOrders();
  }, []);

  const onClickHandle = order => {
    if (order.data.status === 'waiting for payment') {
      navigate(`/customer/delivery/same-day/${order.raw.id}`);
    } else {
      navigate(`/customer/delivery/${order.raw.id}`);
    }
  };

  if (orders === null) {
    return <div>Loading...</div>;
  }

  const render = orders.map((order, index) => {
    return (
      <div
        key={`same-day-order-${index}`}
        style={{
          width: '100%',
          backgroundColor: '#F5F5F5',
          padding: '10px',
          marginBottom: '10px',
          borderRadius: '5px',
          border: 'solid 1px gainsboro',
        }}
        onClick={() => onClickHandle(order)}>
        <Typography variant="body1">
          Same Day Delivery to {toTitleCase(order.data.data.receiver.city)}
        </Typography>
        <Typography variant="body2">
          {order.data?.data['reference-number']}
        </Typography>
        <Typography variant="body2">
          {sameDayStatus[order.data.status] ?? ''}
        </Typography>
        <Typography variant="body2">
          {timeAgo.format(new Date(order.raw.created_last))}
        </Typography>
      </div>
    );
  });

  return (
    <>
      {render}
      {hasNextPage && (
        <Button
          onClick={loadSameDayOrders}
          variant="contained"
          fullWidth
          style={{ marginTop: '20px', float: 'left', marginBottom: '30px' }}>
          Load More
        </Button>
      )}
    </>
  );
};

const RegularOrder = () => {
  const typeUrl = {
    door2door: 'door-2-door',
    dropoff2dropoff: 'dropoff-2-dropoff',
    dropoff2door: 'dropoff-2-door',
    door2dropoff: 'door-2-dropoff',
  };
  const timeAgo = new TimeAgo('en-US');
  const [orders, setOrders] = useState(null);
  const { token } = useContext(UserContext);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const navigate = useNavigate();

  const loadOrders = () => {
    listRegular(token, {
      'current-page': nextPage,
      'record-per-page': 25,
      fields: {
        status: ['draft'],
      },
      operator: '!=',
    }).then(res => {
      if (!orders) {
        setOrders(res.collection);
      } else {
        setOrders([...orders, ...res.collection]);
      }

      let currentPage = res['current-page'] ?? 1;
      let lastPage = res['last-page'] ?? 1;
      setNextPage(currentPage + 1);
      setHasNextPage(lastPage > currentPage);
    });
  };

  useEffect(() => {
    loadOrders();
  }, []);

  const onClickHandle = order => {
    if (order.data.status === 'waiting for payment') {
      navigate(
        `/customer/delivery/regular/${typeUrl[order.data.type]}/${
          order.raw.id
        }`,
      );
    } else {
      navigate(`/customer/delivery/regular/${order.raw.id}`);
    }
  };

  if (orders === null) {
    return <div>Loading...</div>;
  }

  const render = orders.map((order, index) => {
    return (
      <div
        key={`same-day-order-${index}`}
        style={{
          width: '100%',
          backgroundColor: '#F5F5F5',
          padding: '10px',
          marginBottom: '10px',
          borderRadius: '5px',
          border: 'solid 1px gainsboro',
        }}
        onClick={() => onClickHandle(order)}>
        <Typography variant="body1">
          Regular Delivery to {toTitleCase(order.data.data.receiver.city)}
        </Typography>
        <Typography variant="body2">
          {order.data?.data['reference-number']}
        </Typography>
        <Typography variant="body2">
          {renderStatusLabel(order.data.status)}
        </Typography>
        <Typography variant="body2">
          {timeAgo.format(new Date(order.raw.created_last))}
        </Typography>
      </div>
    );
  });

  return (
    <>
      {render}
      {hasNextPage && (
        <Button
          onClick={loadOrders}
          variant="contained"
          fullWidth
          style={{ marginTop: '20px', float: 'left', marginBottom: '30px' }}>
          Load More
        </Button>
      )}
    </>
  );
};
