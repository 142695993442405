import React, { useState, forwardRef, useEffect } from 'react';
import { create } from '../../services/file';
import { Button } from '@mui/material';
import Icon from './Icon';

const ImageUpload = forwardRef(
  ({ label = 'Upload Image', name, type, title, defaultImage = null }, ref) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [url, setUrl] = useState(null);

    useEffect(() => {
      setSelectedImage(null);
      setUrl(null);
    }, [type]);

    useEffect(() => {
      if (defaultImage) {
        setSelectedImage(defaultImage);
      }
    }, [defaultImage]);

    const handleImageChange = event => {
      if (!event.target.files[0]) return;

      if (event.target.files[0].size > 5242880) {
        return alert('File size cannot exceed more than 5MB');
      }

      const imageFile = event.target.files[0];

      if (imageFile.type === 'image/heic') {
        return alert('HEIC files are not supported. Please upload a JPEG or JPG image.');
      }

      create({
        file: imageFile,
        type: type,
        name: name,
      }).then(res => {
        if (res.status) {
          setSelectedImage(null);
          setUrl(null);
          return alert(
            'Image too large or invalid format. Please upload new image.',
          );
        }

        setUrl(res.data.file);
        if (res.data.file) {
          ref.current.push({
            name: name,
            url: res.data.file,
            id: res.raw.id,
          });
        }
      });
      setSelectedImage(URL.createObjectURL(imageFile));
    };

    const handleRemove = () => {
      const temp = ref.current.filter(item => {
        return item.url !== url;
      });
      ref.current = temp;
      setUrl(null);
      setSelectedImage(null);
    };

    return (
      <div
        style={{
          width: '100%',
          marginTop: '10px',
          marginBottom: selectedImage ? '' : '10px',
          textAlign: 'center',
        }}>
        <input
          type="file"
          accept={'image/jpeg, image/jpg'}
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id={'file-input-' + name}
        />
        {!selectedImage && (
          <label htmlFor={'file-input-' + name}>
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                height: '100%',
                background: '#e3e3e3',
                paddingTop: '20px',
                borderRadius: '5px',
              }}>
              {title}
              <br />
              {label}
              
              <Icon />
            </div>
          </label>
        )}
        <small>Please upload a JPEG or JPG image.</small>
        {selectedImage ? (
          <div>
            <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
            <Button
              onClick={() => handleRemove()}
              variant="contained"
              color="error"
              className="action">
              Retake
            </Button>
          </div>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              className="action"
              style={{ marginTop: '10px' }}>
              <label htmlFor={'file-input-' + name}>TAKE PHOTO OR UPLOAD</label>
            </Button>
          </>
        )}
      </div>
    );
  },
);

export default ImageUpload;
