import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar';
import { mainMenu } from '../../../config/dropoffMenu.jsx';
import NavbarAdmin from '../../../components/DesktopNavbar/admin.jsx';
import { mainMenu as mainMenuAdmin } from '../../../config/adminMenu.jsx';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { update, info, autofill } from '../../../services/manifest';
import { listRegular, updateRegular, orderManifestUpdate, orderManifestAccept } from '../../../services/order';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import { Button } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { status as statusLabel } from '../../../config/regularStatus';

let regularLabels = {
  door2door: 'Door 2 Door',
  dropoff2dropoff: 'Dropoff 2 Dropoff',
  dropoff2door: 'Dropoff 2 Door',
  door2dropoff: 'Door 2 Dropoff',
};

const columns = isMobile
  ? [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'reference-number', headerName: 'Order Number', width: 270 },
      { field: 'type', headerName: 'Delivery Type', width: 270 },
      { field: 'orgin', headerName: 'Origin', width: 270 },
      { field: 'destination', headerName: 'Destination', width: 270 },
      { field: 'total', headerName: 'Cost', width: 270 },
      { field: 'payment', headerName: 'Payment Type', width: 270 },
      {field: 'status', headerName: 'Status', width: 270, renderCell: (params) => {
        return <>{statusLabel[params.row.status] ?? ''}</>
      }},
    ]
  : [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'reference-number', headerName: 'Order Number', flex: 1 },
      { field: 'type', headerName: 'Delivery Type', flex: 1 },
      { field: 'orgin', headerName: 'Origin', flex: 1 },
      { field: 'destination', headerName: 'Destination', flex: 1 },
      { field: 'total', headerName: 'Cost', flex: 1 },
      { field: 'payment', headerName: 'Payment Type', flex: 1 },
      {field: 'status', headerName: 'Status', flex: 1, renderCell: (params) => {
        return <>{statusLabel[params.row.status] ?? ''}</>
      }},
    ];

const App = () => {
  const { id, action } = useParams();
  const navigate = useNavigate();
  const { token, userAttr, userType } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0);
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0);
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [manifestInfo, setManifestInfo] = useState(null);
  const [dropoffType, setDropoffType] = useState(null);
  const [userTypeValue, setUserType] = useState(null);

  useEffect(() => {
    if (userAttr) {
      let userAttribute = JSON.parse(userAttr);
      setDropoffType(userAttribute?.type);
    }
  }, [userAttr]);


  useEffect(() => {
    if (userType) {
      setUserType(userType);
    }
  }, []);

  const actionColumns = [
    {
      field: 'action',
      headerName: 'Action',
      width: 250,
      renderCell: params => {
        return (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(
                  `/dropoff/scan/${params.row.id}/${params.row.data.verification}`,
                );
              }}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginRight: '10px',
                    }
              }>
              View
            </Button>

            {(manifestInfo.data['status'] === 'draft' && userTypeValue !== 'admin') && (
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  handleRemove(params.row.id);
                }}
                style={
                  isMobile
                    ? {}
                    : {
                        width: '160px',
                        float: 'right',
                        marginRight: '10px',
                      }
                }>
                Remove
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const handleRemove = id => {
    updateRegular(
      token,
      {
        'manifest-id': 0,
      },
      id,
    ).then(res => {
      loadData({
        'current-page': 1,
        'record-per-page': 25,
      });
    });
  };

  const updatePageHandler = pageInfo => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    });
  };

  const loadData = payload => {
    setLoading(true);
    listRegular(token, {
      ...payload,
      fields: {
        'manifest-id': parseInt(id),
      },
      operator: '=',
      'user-specific': false,
    }).then(res => {
      const summary = [];
      res.collection.forEach(item => {
        summary.push({
          id: item.raw.id,
          ...item.data,
          type: regularLabels[item.data.type],
          orgin: `${item.data['sender-city']}, ${item.data['sender-province']}`,
          destination: `${item.data['receiver-city']}, ${item.data['receiver-province']}`,
          payment: toTitleCase(item.data['payment-type']),
          total: 'PHP ' + moneyFormat(item.data['total']),
        });
      });

      setRows(summary);
      setPageInfoCurrentPage(res['current-page']);
      setPageInfoRowPerPage(res['record-per-page']);
      setPageInfoTotalRows(res['data-total']);
      setLoading(false);
    });
  };

  const loadInformation = () => {
    info(token, id).then(res => {
      setManifestInfo(res);
    });
  };

  const handleReadyForTransfer = () => {
    const sure = window.confirm('Are you sure you want to mark this manifest as ready for transfer?');

    if (!sure) {
      return;
    }

    if (rows.length === 0) {
      alert('No orders in this manifest, please add orders first');
      return;
    }

    update(
      token, {'status': 'ready for transfer'},
      id,
    ).then(res => {
      orderManifestUpdate(token, id, {
        'status': 'transfer inprogress',
      }).then(res => {
        reloadWindow();
      });
    });
  };

  const reloadWindow = () => {
    try {
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAcceptByMainDropoff = () => {
    let sure = window.confirm('Are you sure you want to accept this manifest?');

    if (!sure) {
      return;
    }

    orderManifestAccept(token, id).then(res => {
      alert('Manifest is accepted, you can now dispatch the orders');
      reloadWindow();
    });
  };

  const handleAutoManifest = () => {
    const sure = window.confirm('Are you sure you want to autofill this manifest?');

    if (!sure) {
      return;
    }

    autofill(token, id).then(res => {
      loadData({
        'current-page': 1,
        'record-per-page': 25,
      });
    });
  };

  useEffect(() => {
    loadInformation();
    loadData({
      'current-page': 1,
      'record-per-page': 25,
    });
  }, []);

  if (!manifestInfo) {
    return null;
  }

  return (
    <>
      {
        userTypeValue == 'admin' 
        ? (<NavbarAdmin pages={mainMenuAdmin} />) 
        : (<Navbar pages={mainMenu} />)
      }
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={4} style={{ height: '100px' }}>
            <h1>Manifest Information</h1>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            style={
              isMobile
                ? { height: '100px' }
                : { height: '100px', paddingTop: '40px' }
            }>
            <Button
              variant="contained"
              onClick={() => {
                userTypeValue == 'admin' 
                  ? navigate('/admin/regular-manifest')
                  : navigate('/dropoff/manifest')
              }}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                    }
              }>
              Back
            </Button>
      
            {userTypeValue !== 'admin' && <>
            <Button
              variant="contained"
              onClick={() => handleAutoManifest()}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginRight: '10px',
                    }
              }>
              AutoFill
            </Button></> }

            <Button
              variant="contained"
              onClick={() => {
                window.open(`/dropoff/manifest/${id}/print`, '_blank');
              }}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginRight: '10px',
                    }
              }>
              Print
            </Button>
            
            {manifestInfo.data.status =='draft' && userTypeValue !== 'admin' && (
            <Button
              variant="contained"
              onClick={() => handleReadyForTransfer()}
              style={
                isMobile
                  ? {}
                  : {
                      width: '260px',
                      float: 'right',
                      marginRight: '10px',
                    }
              }>
              Ready for transfer
            </Button>)}

            {(action && action === 'accept' && dropoffType === 'main' && manifestInfo.data.status =='ready for transfer') && (
              <Button
                variant="contained"
                onClick={() => handleAcceptByMainDropoff()}
                style={
                  isMobile
                    ? {}
                    : {
                        width: '260px',
                        float: 'right',
                        marginRight: '10px',
                      }
                }>
                Accept Manifest
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={6} style={{ height: '250px' }}>
            <p>
              <strong>Status:</strong> {toTitleCase(manifestInfo.data.status)}
            </p>
            <p>
              <strong>Manifest Number:</strong>{' '}
              {manifestInfo.data['reference-id']}
            </p>
            <p>
              <strong>Manifest Name:</strong> {manifestInfo.data['name']}
            </p>
            <p>
              <strong>Origin:</strong> {manifestInfo.data['origin']}
            </p>
            <p>
              <strong>Destination:</strong> {manifestInfo.data['destination']}
            </p>
            <p>
              <strong>Description:</strong> {manifestInfo.data['description']}
            </p>
          </Grid>

          <Grid item xs={12} sm={12}>
            <DataGrid
              rows={rows}
              columns={[...columns, ...actionColumns]}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
                pagination: {
                  paginationModel: {
                    pageSize: pageInfoRowPerPage,
                    page: pageInfoCurrentPage,
                  },
                },
              }}
              pagination
              paginationMode="server"
              rowCount={pageInfoTotalRows}
              loading={loading}
              onPaginationModelChange={newPage => updatePageHandler(newPage)}
              disableColumnFilter
              disableColumnMenu
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
