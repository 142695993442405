import React, { useState } from 'react';
import { Button, Container, List, ListItem, ListItemText, Typography, TextField, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ShareLink = ({ url, text }) => {
  const encodedURL = encodeURIComponent(url);
  const encodedText = encodeURIComponent(text);
  const [copySuccess, setCopySuccess] = useState('');

  const emailLink = `mailto:?subject=${encodedText}&body=${encodedURL}`;
  const twitterLink = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedURL}`;
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`;

  const handleCopy = () => {
    setCopySuccess('Copied!');
    setTimeout(() => {
      setCopySuccess('');
    }, 2000);
  };

  return (
    <Container maxWidth="sm" style={{ padding: '20px', textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Share this link:
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        value={url}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <CopyToClipboard text={url} onCopy={handleCopy}>
              <IconButton>
                <ContentCopyIcon />
              </IconButton>
            </CopyToClipboard>
          ),
        }}
      />
      {copySuccess && (
        <Typography variant="body2" color="success.main">
          {copySuccess}
        </Typography>
      )}
      <List>
        <ListItem button component="a" href={emailLink} target="_blank" rel="noopener noreferrer">
          <EmailIcon style={{ marginRight: '8px' }} />
          <ListItemText primary="Share via Email" />
        </ListItem>
        <ListItem button component="a" href={twitterLink} target="_blank" rel="noopener noreferrer">
          <TwitterIcon style={{ marginRight: '8px' }} />
          <ListItemText primary="Share on Twitter" />
        </ListItem>
        <ListItem button component="a" href={facebookLink} target="_blank" rel="noopener noreferrer">
          <FacebookIcon style={{ marginRight: '8px' }} />
          <ListItemText primary="Share on Facebook" />
        </ListItem>
      </List>
    </Container>
  );
};

export default ShareLink;
