import React, { useContext, useState } from 'react';
import Navbar from '../../../components/DesktopNavbar';
import { mainMenu } from '../../../config/dropoffMenu.jsx';
import { UserContext } from '../../../context/UserProvider.jsx';
import { get } from '../../../services/report';
import { Card, Typography, Box, Avatar } from '@mui/material';
import SoapIcon from '@mui/icons-material/Soap';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { isMobile } from 'react-device-detect';

const App = () => {
  const { userAttr } = useContext(UserContext);
  const [type, setType] = useState(null);
  const [report, setReport] = useState(null);
  const { token } = useContext(UserContext);

  const loadReport = () => {
    get(token, 'dropoff')
      .then(response => {
        setReport(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useState(() => {
    let hasQuery = window.location.search;
    let isReloadedByMobile = hasQuery.includes('mobile');

    if (isMobile && !isReloadedByMobile) {
      window.location.reload(true);
      window.location.href = window.location.href + '?mobile';
    }

    loadReport();
  }, []);

  React.useEffect(() => {
    if (userAttr) {
      setType(JSON.parse(userAttr)?.type ?? 'sub');
    }
  }, [userAttr]);

  const ReportContainer = () => {
    if (!report) {
      return <div style={{textAlign: 'center'}}>Loading...</div>;
    }
    return (
      <div>
        <TrafficCard
          data={report}
          keyReport={'OnHandParcel'}
          color={'#d6c5ff'}
          icon={<SoapIcon />}
          iconColor={'#000000'}
        />
        <TrafficCard
          data={report}
          keyReport={'InTransferParcel'}
          color={'#a385ed'}
          icon={<LocalShippingIcon />}
          iconColor={'#000000'}
        />
        <TrafficCard
          data={report}
          keyReport={'TotalParcel'}
          color={'#8f6ae9'}
          icon={<WidgetsIcon />}
          iconColor={'#000000'}
        />
      </div>
    );
  };

  return (
    <>
      <Navbar pages={mainMenu} />
      <div
        style={
          isMobile
            ? {
                padding: '10px',
              }
            : {
                margin: '10px',
              }
        }>
        {type == 'sub' ? (
          <>
            {' '}
            <h1>Sub Dropoff</h1>
          </>
        ) : (
          <>
            {' '}
            <h1>Main Dropoff</h1>
          </>
        )}
      </div>
      <ReportContainer />
    </>
  );
};

export default App;

const TrafficCard = ({ data, keyReport, icon, color, iconColor }) => {
  console.log(data);
  if (!data[keyReport]) {
    return null;
  }

  return (
    <div
      style={
        isMobile
          ? {
              padding: '10px',
            }
          : {
              float: 'left',
              padding: '10px',
              width: '21%',
            }
      }>
      <Card
        sx={{
          padding: 2,
          backgroundColor: color,
          borderRadius: '16px',
          width: '90%',
          margin: isMobile ? 'auto' : '0',
        }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="body2" color="textSecondary">
              {data[keyReport].label.toUpperCase()}
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              {data[keyReport].value.toLocaleString()}
            </Typography>
          </Box>
          <Avatar sx={{ backgroundColor: iconColor }}>{icon}</Avatar>
        </Box>
      </Card>
    </div>
  );
};
