import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../../../components/DesktopNavbar';
import { mainMenu } from '../../../config/dropoffMenu.jsx';
import { UserContext } from '../../../context/UserProvider.jsx';
import { getAccountInformation } from '../../../services/user.jsx';
import LoadingScreen from '../../../components/LoadingScreen';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';

const App = () => {
  const { token } = useContext(UserContext);
  const [accountInformation, setAccountInformation] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);

  const loadAccountInformation = async () => {
    const data = await getAccountInformation(token);
    setAccountInformation(data?.dropoff);
    setMobileNumber(data?.user?.login);
  };

  useEffect(() => {
    loadAccountInformation();
  }, []);

  if (!accountInformation) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} style={{ height: '100px' }}>
            <h1>My Account</h1>
          </Grid>
          <Grid item xs={12} style={{ height: '100px' }}>
            <strong>Account Type:</strong>{' '}
            <span>Dropoff - {accountInformation['type']}</span>
            <br />
            <strong>Name:</strong>{' '}
            <span>
              {accountInformation['first-name']}{' '}
              {accountInformation['last-name']}
            </span>
            <br />
            <strong>Email:</strong>{' '}
            <span>{accountInformation['email-address']}</span>
            <br />
            <strong>Mobile:</strong> <span>{mobileNumber}</span>
            <br />
            <strong>Address:</strong>{' '}
            <span>
              {accountInformation['address-line-1']}{' '}
              {accountInformation['address-line-2'] || ''}{' '}
              {accountInformation['address-city']}{' '}
              {accountInformation['address-province']}{' '}
              {accountInformation['address-zip-code']}
            </span>
            <br />
          </Grid>
        </Grid>
        <Button
          onClick={() => {
            window.location.href =
              'https://boxtrackcargo.com.ph/account-deletion';
          }}
          variant="outlined"
          color="error"
          fullWidth
          style={{ float: 'left', marginBottom: '30px', marginTop: '50px' }}>
          Delete My Account
        </Button>
      </Container>
    </>
  );
};

export default App;
