import React, { createContext, useEffect, useState, useRef } from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') ?? null);
  const [userType, setUserType] = useState(localStorage.getItem('userType') ?? null);
  const [userAttr, setUserAttr] = useState(localStorage.getItem('userAttr') ?? null);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }

    loadAppilix();
  }, [token]);

  useEffect(() => {
    if (userType) {
      localStorage.setItem('userType', userType);
    }
  }, [userType]);


  useEffect(() => {
    if (userAttr) {
      localStorage.setItem('userAttr', userAttr);
    }
  }, [userAttr]);

  useEffect(() => {
    loadAppilix();
  }, []);

  const loadAppilix = () => {
    var appilix_push_notification_user_identity = localStorage.getItem('appilix_push_notification_user_identity');
    if (appilix_push_notification_user_identity) {
      document.cookie = `appilix_push_notification_user_identity=${appilix_push_notification_user_identity}`;
    }
  }

  const logout = () => {
    setToken(null);
    setUserType(null);
    setUserAttr(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('userAttr');
  };

  const getCookie = (name) => {
     const value = `; ${document.cookie}`;
     const parts = value.split(`; ${name}=`);
     if (parts.length === 2) return parts.pop().split(';').shift() ?? null;
  }

  const TestMode = () => {
    if (!isTest()) return null;
    const appilix = getCookie('appilix_push_notification_user_identity'); 

    return (
      <div
        style={{
          top: '0',
          left: '0',
          zIndex: '9999',
          background: 'green',
          color: 'white',
          padding: '5px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {appilix ? <>TEST MODE - {appilix}</> : <>TEST MODE</>}
      </div>
    );
  }

  const isTest = () => {
    const domain = window.location.hostname;
    return domain === 'portal.boxtrackcargo.com.ph' ? false : true;
  }

  return (
    <UserContext.Provider
      value={{ token, setToken, logout, userType, setUserType, userAttr, setUserAttr, isTest}}
    >
       <TestMode />
      {children}
    
    </UserContext.Provider>
  );
};

export default UserProvider;
export { UserContext };
