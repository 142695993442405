import React, { useContext } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserProvider';
import DeliveryTemplateProvider from '../context/DeliveryTemplateProvider';
import { backgroundColors } from '../utils/constants.jsx';
import { StyleContext } from '../context/StyleProvider.jsx';
import { isMobile, isDesktop } from 'react-device-detect';

// LoginPage
import LoginPage from './driver/LoginPage/index.jsx';
import NotFound from './system/NotFound/index.jsx';
import MobileOnly from './system/MobileOnly/index.jsx';

// Driver
import Signup from './driver/SignupPage/index.jsx';
import Main from './index.jsx';
import ForgotPassword from './driver/LoginPage/recoverForm.jsx';
import DriverProtectedLayout from './driver/Dashboard/index.jsx';
import DriverDashboardPage from './driver/Dashboard/dashboard.jsx';
import DriverWalletPage from './driver/Wallet/index.jsx';
import DriverWalletTransaction from './driver/Wallet/transaction.jsx';
import DriverOrderPage from './driver/Order/index.jsx';
import DriverOrderSameDayInformation from './driver/Order/sameDayInformation.jsx';
import DriverHistoryOrder from './driver/Order/history.jsx';
import DriverOrderRegularInformation from './driver/Order/regularInformation.jsx';
import DriverOrderRegularForDeliveryScan from './driver/Order/regularForDeliveryScan.jsx';
import DriverMap from './driver/DrivingMap/index.jsx';
import DriverProfile from './driver/Profile/index.jsx';

// Customer
import CustomerLoginPage from './customer/LoginPage/index.jsx';
import CustomerRecoverForm from './customer/LoginPage/recoverForm.jsx';
import CustomerSignup from './customer/SignupPage/index.jsx';
import CustomerProtectedLayout from './customer/Dashboard/index.jsx';
import CustomerWallet from './customer/Dashboard/wallet.jsx';
import CustomerDashboardPage from './customer/Dashboard/dashboard.jsx';
import CustomerDeliverySameDay from './customer/DeliverySameDay/index.jsx';
import CustomerDeliverySameDayInformation from './customer/DeliverySameDay/information.jsx';
import CustomerDeliveryRegular from './customer/DeliveryRegular/index.jsx';
import CustomerDeliveryRegularDoor2Door from './customer/DeliveryRegular/Door2Door/index.jsx';
import CustomerDeliveryRegularInformation from './customer/DeliveryRegular/Information';
import CustomerDeliveryRegularDropOff2DropOff from './customer/DeliveryRegular/DropOff2DropOff/index.jsx';
import CustomerDeliveryRegularDropOff2Door from './customer/DeliveryRegular/DropOff2Door/index.jsx';
import CustomerDeliveryRegularDoor2DropOff from './customer/DeliveryRegular/Door2DropOff/index.jsx';
import CustomerAffiliation from './customer/Affiliation/index.jsx';
import CustomerOrderHistory from './customer/Dashboard/history.jsx';
import CustomerProfile from './customer/Profile/index.jsx';

//Dropoff
import DropoffLoginPage from './dropoff/LoginPage';
import DropoffRecoverForm from './dropoff/LoginPage/recoverForm.jsx';
import DropoffSignup from './dropoff/SignupPage/index.jsx';
import DropoffDashboard from './dropoff/Dashboard/index.jsx';
import DropoffScan from './dropoff/Scan/index.jsx';
import DropoffWaybill from './dropoff/Waybill/index.jsx';
import DropoffParcel from './dropoff/Parcels/index.jsx';
import DropoffManifest from './dropoff/Manifest/index.jsx';
import DropoffCreateManifest from './dropoff/Manifest/create.jsx';
import DropoffManifestInfo from './dropoff/Manifest/info.jsx';
import DrropoffParcelToManifest from './dropoff/Parcels/manifest.jsx';
import DropoffPrint from './dropoff/Manifest/print.jsx';
import DropoffReceived from './dropoff/Received/index.jsx';
import DropoffReceivedAccept from './dropoff/Received/accept.jsx';
import DropoffReceivedParcels from './dropoff/Received/parcels.jsx';
import DropoffParcelPickup from './dropoff/Parcels/pickup.jsx';
import DropoffWallet from './dropoff/Wallet/index.jsx';
import DropoffWalletTransaction from './dropoff/Wallet/transaction.jsx';
import DropoffAccountInformation from './dropoff/Account/index.jsx';

//Admin
import AdminLoginPage from './admin/LoginPage/index.jsx';
import AdminRecoverForm from './admin/LoginPage/recoverForm.jsx';
import AdminSignup from './admin/SignupPage/index.jsx';
import AdminDashboard from './admin/Dashboard/index.jsx';
import AdminDrivers from './admin/Drivers/index.jsx';
import AdminDriverInformation from './admin/Drivers/information.jsx';
import AdminWalletInformation from './admin/Wallet/index.jsx';
import AdminWalletTransaction from './admin/Wallet/transaction.jsx';
import AdminCustomer from './admin/Customer/index.jsx';
import AdminCustomerInformation from './admin/Customer/information.jsx';
import AdminDropoff from './admin/Dropoff/index.jsx';
import AdminDropoffInformation from './admin/Dropoff/information.jsx';
import AdminRegularOrders from './admin/RegularOrders/index.jsx';
import AdminRegularOrdersInformation from './admin/RegularOrders/information.jsx';
import AdminRegularOrderManifest from './admin/RegularOrderManifest/index.jsx';
import AdminSameDayOrders from './admin/SameDayOrders/index.jsx';
import AdminSameDayOrdersInformation from './admin/SameDayOrders/information.jsx';

//Transaction
import MainTransaction from './transaction/index.jsx';

const publicRoutes = [
  '/',
  '/driver',
  '/driver/recover',
  '/driver/signup',
  '/customer',
  '/customer/recover',
  '/customer/signup',
];

function Controller() {
  const { token, userType, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const navigateTo = path => {
    navigate(path);
  };
  const { pathname } = useLocation();
  const { setBackgroundColor } = useContext(StyleContext);

  const deliveryTemplate = (type, pricePage, components) => {
    return (
      <DeliveryTemplateProvider type={type} pricePage={pricePage}>
        {components}
      </DeliveryTemplateProvider>
    );
  };

  if (backgroundColors[pathname]) {
    setTimeout(() => {
      setBackgroundColor(token ? '#fff' : backgroundColors[pathname]);
    }, 1);
  }

  const currentURL = window.location.pathname;

  console.log('currentURL', currentURL);
  console.log('VERSION', '1.0.0');
    if (currentURL.includes('/transaction-view')) {
      return (
        <Routes>
          <Route path="/transaction-view" element={<MainTransaction />} />
        </Routes>
      );
    } else if (currentURL.includes('/dropoff/')) {
      return (
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/dropoff/login" element={<DropoffLoginPage />} />
          <Route path="/dropoff/signup" element={<DropoffSignup />} />
          <Route path="/dropoff/recover" element={<DropoffRecoverForm />} />
          <Route path="/dropoff/dashboard" element={<DropoffDashboard />} />
          <Route path="/dropoff/scan/:id?/:code?" element={<DropoffScan />} />
          <Route path="/dropoff/waybill/:id" element={<DropoffWaybill />} />
          <Route path="/dropoff/parcels" element={<DropoffParcel />} />
          <Route path="/dropoff/manifest" element={<DropoffManifest />} />
          <Route
            path="/dropoff/manifest/create"
            element={<DropoffCreateManifest />}
          />
          <Route
            path="/dropoff/manifest/:id"
            element={<DropoffManifestInfo />}
          />
          <Route
            path="/dropoff/parcel/:id/manifest"
            element={<DrropoffParcelToManifest />}
          />
          <Route
            path="/dropoff/manifest/:id/print"
            element={<DropoffPrint />}
          />
          <Route
            path="/dropoff/manifest/:id/:action?"
            element={<DropoffManifestInfo />}
          />
          <Route path="/dropoff/received" element={<DropoffReceived />} />
          <Route
            path="/dropoff/received/parcels"
            element={<DropoffReceivedParcels />}
          />
          <Route
            path="/dropoff/received/accept"
            element={<DropoffReceivedAccept />}
          />
          <Route
            path="/dropoff/parcel/:id/pickup"
            element={<DropoffParcelPickup />}
          />
          <Route path="/dropoff/wallet" element={<DropoffWallet />} />
          <Route path="/dropoff/wallet/transaction" element={<DropoffWalletTransaction />} />
          <Route path="/dropoff/account" element={<DropoffAccountInformation />} />

          <Route path="/dropoff/*" element={<NotFound />} />
          <Route path="*" element={<MobileOnly />} />
        </Routes>
      );
    } else if (currentURL.includes('/admin/') && isDesktop) {
      return (
        <Routes>
          <Route path="/admin/login" element={<AdminLoginPage />} />
          <Route path="/admin/signup" element={<AdminSignup />} />
          <Route path="/admin/recover" element={<AdminRecoverForm />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/drivers" element={<AdminDrivers />} />
          <Route path="/admin/driver/:id" element={<AdminDriverInformation />} />

          <Route path="/admin/customers" element={<AdminCustomer />} />
          <Route path="/admin/customer/:id" element={<AdminCustomerInformation />} />

          <Route path="/admin/wallet/:type/:id" element={<AdminWalletInformation />} />
          <Route path="/admin/wallet/transaction/:type/:id" element={<AdminWalletTransaction />} />

          <Route path="/admin/dropoff-partners" element={<AdminDropoff />} />
          <Route path="/admin/dropoff-partner/:id" element={<AdminDropoffInformation />} />

          <Route path="/admin/orders-regular" element={<AdminRegularOrders />} />
          <Route path="/admin/orders-regular/:id" element={<AdminRegularOrdersInformation />} />
          <Route path="/admin/orders-regular/customer/:id" element={<AdminRegularOrders />} />

          <Route path="/admin/orders-same-day" element={<AdminSameDayOrders />} />
          <Route path="/admin/orders-same-day/:id" element={<AdminSameDayOrdersInformation />} />
          <Route path="/admin/orders-same-day/customer/:id" element={<AdminSameDayOrders />} />

          <Route path="/admin/regular-manifest" element={<AdminRegularOrderManifest />} />

          <Route path="/admin/*" element={<NotFound />} />
        </Routes>
      );
    } else if (currentURL === '/' && isDesktop) {
      if (userType === 'driver' || userType === 'customer') {
        console.log('userType', userType);
        return logout();
      }

      if (userType) {
        return window.location.href = `/${userType}/dashboard`;
      }

     
    } else if ([
      '/customer', 
      '/customer/', 
      '/customer/dashboard', 
      '/driver',
      '/driver/',
      '/driver/dashboard'
    ].includes(currentURL) && isDesktop) {
      return (
        <Routes>
          <Route path="/*" element={<MobileOnly />} />
        </Routes>
      );
    }

   
  if (token && publicRoutes.includes(pathname)) {
    let route = null;
    if (userType === 'driver') {
      route = (
        <Route
          path={pathname}
          element={<DriverDashboardPage navigate={navigateTo} />}
        />
      );
    } else if (userType === 'customer') {
      route = (
        <Route
          path={pathname}
          element={<CustomerDashboardPage navigate={navigateTo} />}
        />
      );
    } else if (userType === 'dropoff') {
      route = (
        <Route
          path={pathname}
          element={<DropoffDashboard />}
        />
      );
    }

    return <Routes>{route}</Routes>;
  }

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/driver" element={<LoginPage navigate={navigateTo} />} />
      <Route
        path="/driver/recover"
        element={<ForgotPassword navigate={navigateTo} />}
      />
      <Route path="/driver/signup" element={<Signup navigate={navigateTo} />} />
      <Route path="/driver" element={<DriverProtectedLayout />}>
        <Route
          path="dashboard"
          element={<DriverDashboardPage navigate={navigateTo} />}
        />
        <Route
          path='profile'
          element={<DriverProfile navigate={navigateTo} />}
        />  
        <Route
          path="wallet"
          element={<DriverWalletPage navigate={navigateTo} />}
        />
        <Route
          path="wallet/transaction"
          element={<DriverWalletTransaction navigate={navigateTo} />}
        />
        <Route
          path="orders"
          element={<DriverOrderPage navigate={navigateTo} />}
        />
        <Route
          path="orders/scan"
          element={<DriverOrderRegularForDeliveryScan navigate={navigateTo} />}
        />
        <Route
          path="orders/sameday/:id"
          element={<DriverOrderSameDayInformation navigate={navigateTo} />}
        />
        <Route
          path="map/sameday/:id/:destinationLat/:destinationLng"
          element={<DriverMap />}
        />
        <Route
          path="orders/regular/:id"
          element={<DriverOrderRegularInformation navigate={navigateTo} />}
        />
        <Route
          path="history/orders"
          element={<DriverHistoryOrder navigate={navigateTo} />}
        />
      </Route>

      {/* CUSTOMER SECTION */}
      <Route
        path="/customer"
        element={
          token ? (
            <CustomerDashboardPage navigate={navigateTo} />
          ) : (
            <CustomerLoginPage navigate={navigateTo} />
          )
        }
      />
      <Route
        path="/customer/recover"
        element={<CustomerRecoverForm navigate={navigateTo} />}
      />
      <Route
        path="/customer/signup/:refCode?"
        element={<CustomerSignup navigate={navigateTo} />}
      />
      <Route path="/customer" element={<CustomerProtectedLayout />}>
        <Route
          path="order-history"
          element={<CustomerOrderHistory navigate={navigateTo} />}
        />
        <Route
          path="profile"
          element={<CustomerProfile navigate={navigateTo} />}
        />
        <Route
          path="dashboard"
          element={<CustomerDashboardPage navigate={navigateTo} />}
        />
        <Route
          path="affiliation"
          element={<CustomerAffiliation navigate={navigateTo} />}
        />
        <Route
          path="wallet"
          element={<CustomerWallet navigate={navigateTo} />}
        />
        <Route
          path="delivery/same-day"
          element={<CustomerDeliverySameDay navigate={navigateTo} />}
        />
        <Route
          path="delivery/same-day/:id"
          element={<CustomerDeliverySameDay navigate={navigateTo} />}
        />
        <Route
          path="delivery/:id"
          element={<CustomerDeliverySameDayInformation navigate={navigateTo} />}
        />
        <Route
          path="delivery/regular"
          element={<CustomerDeliveryRegular navigate={navigateTo} />}
        />

        <Route
          path="delivery/regular/door-2-door/:id?"
          element={deliveryTemplate(
            'door2door',
            4,
            <CustomerDeliveryRegularDoor2Door navigate={navigateTo} />,
          )}
        />
        <Route
          path="delivery/regular/door-2-dropoff/:id?"
          element={deliveryTemplate(
            'door2dropoff',
            5,
            <CustomerDeliveryRegularDoor2DropOff navigate={navigateTo} />,
          )}
        />
        <Route
          path="delivery/regular/dropoff-2-dropoff/:id?"
          element={deliveryTemplate(
            'dropoff2dropoff',
            6,
            <CustomerDeliveryRegularDropOff2DropOff navigate={navigateTo} />,
          )}
        />
        <Route
          path="delivery/regular/dropoff-2-door/:id?"
          element={deliveryTemplate(
            'dropoff2door',
            5,
            <CustomerDeliveryRegularDropOff2Door navigate={navigateTo} />,
          )}
        />

        <Route
          path="delivery/regular/:id"
          element={<CustomerDeliveryRegularInformation navigate={navigateTo} />}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Controller;
