import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import DatePicker from './DatePicker';
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Fields({
  config,
  errors,
  register,
  control,
  selections = [],
  defaultValues = {},
}) {
  const [showPassword, setShowPassword] = useState(false);

  // check if how many elements is password
  const passwordLength = config.filter(element => element.isPassword).length;
  let passwordIndex = 0;

  const buildFields = config => {
    const builtFields = [];

    config.forEach(element => {
      let render;
      switch (element.type) {
        case 'textfield':
          if (element.isPassword) {
            passwordIndex += 1;
          }
          render = (
            <div key={element.id}>
              <TextField
                key={element.id}
                label={element.label}
                variant="outlined"
                error={!!errors[element.id]}
                {...register(element.id, { required: element.required })}
                type={!showPassword && element.isPassword ? 'password' : 'text'}
                inputProps={element.inputProps ?? {}}
              />

              {element.isPassword && passwordIndex == passwordLength && (
                <Button
                  type="button"
                  variant="outlined"
                  className="action"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ width: '100%' }}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}{' '}
                  {showPassword ? 'Hide Password' : 'Show Password'}
                </Button>
              )}
            </div>
          );
          break;
        case 'select':
          if (!Object.keys(selections).length) return;
          render = (
            <FormControl key={element.id}>
              <InputLabel>{element.label}</InputLabel>
              <Select
                control={control}
                label={element.label}
                error={!!errors[element.id]}
                {...register(element.id)}
                defaultValue={defaultValues[element.id]}>
                {selections[element.id].map((item, index) => {
                  return (
                    <MenuItem
                      key={`selection-${element.id}-${index}`}
                      value={item.name ?? item.value}>
                      {item.name ?? item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
          break;
        case 'switch':
          render = (
            <FormControlLabel
              key={element.id}
              control={
                <Switch
                  color="primary"
                  {...register(element.id)}
                  defaultChecked={element.value}
                />
              }
              label={element.label}
              labelPlacement="start"
            />
          );
          break;
        case 'date':
          render = (
            <DatePicker
              key={element.id}
              label={element.label}
              control={control}
              format={element.format}
              maxDate={element.maxDate}
              error={!!errors[element.id]}
              {...register(element.id, { required: element.required })}
            />
          );
          break;
        default:
      }

      builtFields.push(render);
    });

    return builtFields;
  };

  return buildFields(config);
}

export default Fields;
