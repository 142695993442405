import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { getAccountInformation } from '../../../services/user';
import { update } from '../../../services/driver';
import { update as updateVehicle } from '../../../services/vehicle';
import { UserContext } from '../../../context/UserProvider';
import { styles } from '../../../config/defaultPageStyles';
import ImageLoader from '../../../components/ImageLoader';
import CaptureForm from '../SignupPage/captureForm';
import VehicleForm from '../SignupPage/vehicleForm';

const Loading = () => (
  <div
    style={{
      ...styles.pageContainer,
      minHeight: '100vh',
      height: 'auto',
      textAlign: 'center',
    }}>
    Loading...
  </div>
);

const Profile = ({ driver, user, setInitUpdate }) => (
  <div style={{ marginTop: '20px' }}>
    <div>
      <strong>Name: </strong> {driver['first-name'] ?? ''}{' '}
      {driver['last-name'] ?? ''}
    </div>
    <div>
      <strong>Email: </strong> {driver['email-address'] ?? ''}
    </div>
    <div>
      <strong>Phone: </strong> {user['login'] ?? ''}
    </div>
    <div>
      <strong>Address: </strong> {driver['address-line-1'] ?? ''}{' '}
      {driver['address-line-2'] ?? ''}
    </div>
    <div>
      <strong>City: </strong> {driver['address-city'] ?? ''}
    </div>
    <div>
      <strong>State / Province: </strong> {driver['address-province'] ?? ''}
    </div>
    <div>
      <strong>Zip Code: </strong> {driver['address-zip-code'] ?? ''}
    </div>
    <div>
      <strong>Birth Date: </strong> {driver['birth-date'] ?? ''}
    </div>
    <div>
      <strong>Valid Identification Card: </strong>
      <ImageLoader
        id={driver['valid-identification-card-file-id']}
        width="100%"
        clickable={false}
      />
      <Button
        onClick={() => setInitUpdate('Identification Card')}
        variant="outlined"
        fullWidth
        style={{ marginTop: '5px', float: 'left', marginBottom: '30px' }}>
        Update
      </Button>
    </div>
    <div>
      <strong>Drivers License: </strong>
      <ImageLoader
        id={driver['drivers-license-file-id']}
        width="100%"
        clickable={false}
      />
      <Button
        onClick={() => setInitUpdate('Drivers License')}
        variant="outlined"
        fullWidth
        style={{ marginTop: '5px', float: 'left', marginBottom: '30px' }}>
        Update
      </Button>
    </div>
  </div>
);

const Vehicle = ({ vehicle, setInitUpdate }) => (
  <div style={{ marginTop: '20px' }}>
    <div style={styles.pageHeader}>Vehicle Information</div>
    <div>
      <strong>Brand: </strong> {vehicle['brand'] ?? ''}
    </div>
    <div>
      <strong>Model: </strong> {vehicle['model'] ?? ''}
    </div>
    <div>
      <strong>Color: </strong> {vehicle['color'] ?? ''}
    </div>
    <div>
      <strong>Plate Number: </strong> {vehicle['plate-number'] ?? ''}
    </div>
    <div>
      <strong>Type: </strong> {vehicle['type'] ?? ''}
    </div>
    <div>
      <strong>Validity Date: </strong> {vehicle['validity-date'] ?? ''}
    </div>

    <Button
      onClick={() => setInitUpdate('Vehicle Information')}
      variant="outlined"
      fullWidth
      style={{ marginTop: '5px', float: 'left', marginBottom: '30px' }}>
      Update
    </Button>
    <div>
      <strong>Certificate of Registration: </strong>
      <ImageLoader
        id={vehicle['certification-of-registration-file-id']}
        width="100%"
        clickable={false}
      />
      <Button
        onClick={() => setInitUpdate('Certificate of Registration')}
        variant="outlined"
        fullWidth
        style={{ marginTop: '5px', float: 'left', marginBottom: '30px' }}>
        Update
      </Button>
    </div>
    <div>
      <strong>Official Receipt: </strong>
      <ImageLoader
        id={vehicle['official-receipt-file-id']}
        width="100%"
        clickable={false}
      />
      <Button
        onClick={() => setInitUpdate('Official Receipt')}
        variant="outlined"
        fullWidth
        style={{ marginTop: '5px', float: 'left', marginBottom: '30px' }}>
        Update
      </Button>
    </div>
  </div>
);

const App = () => {
  const [data, setData] = useState(null);
  const { token } = useContext(UserContext);
  const navigate = useNavigate();
  const [initUpdate, setInitUpdate] = useState(null);

  const loadData = () => {
    getAccountInformation(token).then(response => {
      setData(response);
      console.log(response);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!data) {
    return <Loading />;
  }

  const { driver, user, vehicle } = data;

  if (initUpdate == 'Identification Card') {
    return (
      <>
        <CaptureForm
          type={'id'}
          stateName={'userData'}
          userData={data}
          nextStep={() => {}}
          prevStep={() => {
            setInitUpdate(null);
          }}
          handleFormSubmit={(stateName, resultData) => {
            update(token, user['info-id'], {
              'valid-identification-card-file-id':
                resultData['valid-identification-card-file-id'],
            }).then(response => {
              loadData();
              setInitUpdate(null);
            });
          }}
        />
      </>
    );
  } else if (initUpdate == 'Drivers License') {
    return (
      <>
        <CaptureForm
          type={'license'}
          stateName={'userData'}
          userData={data}
          nextStep={() => {}}
          prevStep={() => {
            setInitUpdate(null);
          }}
          handleFormSubmit={(stateName, resultData) => {
            update(token, user['info-id'], {
              'drivers-license-file-id': resultData['drivers-license-file-id'],
            }).then(response => {
              loadData();
              setInitUpdate(null);
            });
          }}
        />
      </>
    );
  } else if (initUpdate == 'Certificate of Registration') {
    return (
      <>
        <CaptureForm
          type={'registration'}
          stateName={'userData'}
          userData={data}
          nextStep={() => {}}
          prevStep={() => {
            setInitUpdate(null);
          }}
          handleFormSubmit={(stateName, resultData) => {
            updateVehicle(token, vehicle['id'], {
              'certification-of-registration-file-id':
                resultData['certificate-of-registration-file-id'],
            }).then(response => {
              loadData();
              setInitUpdate(null);
            });
          }}
        />
      </>
    );
  } else if (initUpdate == 'Official Receipt') {
    return (
      <>
        <CaptureForm
          type={'receipt'}
          stateName={'userData'}
          userData={data}
          nextStep={() => {}}
          prevStep={() => {
            setInitUpdate(null);
          }}
          handleFormSubmit={(stateName, resultData) => {
            updateVehicle(token, vehicle['id'], {
              'official-receipt-file-id':
                resultData['vehicle-official-receipt-file-id'],
            }).then(response => {
              loadData();
              setInitUpdate(null);
            });
          }}
        />
      </>
    );
  } else if (initUpdate == 'Vehicle Information') {
    return (
      <>
        <VehicleForm
          type={'receipt'}
          stateName={'userData'}
          vehicleData={vehicle}
          nextStep={() => {}}
          prevStep={() => {
            setInitUpdate(null);
          }}
          handleFormSubmit={(stateName, resultData) => {
            updateVehicle(token, vehicle['id'], {
              brand: resultData['brand'],
              model: resultData['model'],
              color: resultData['color'],
              'plate-number': resultData['plate-number'],
              type: resultData['type'].value,
              'validity-date': resultData['validity-date'],
            }).then(response => {
              loadData();
              setInitUpdate(null);
            });
          }}
        />
      </>
    );
  }

  return (
    <div
      style={{ ...styles.pageContainer, minHeight: '100vh', height: 'auto' }}>
      <div style={styles.pageHeader}>My Profile</div>
      <Profile driver={driver} user={user} setInitUpdate={setInitUpdate} />
      <Vehicle vehicle={vehicle} setInitUpdate={setInitUpdate} />
      <Button
        onClick={() => navigate(-1)}
        variant="contained"
        fullWidth
        style={{ marginTop: '20px', float: 'left', marginBottom: '30px' }}>
        BACK
      </Button>
      <Button
        onClick={() => {
          window.location.href = 'https://boxtrackcargo.com.ph/account-deletion';
        }}
        variant="outlined"
        color="error"
        fullWidth
        style={{ float: 'left', marginBottom: '30px' }}>
        Delete My Account
      </Button>
    </div>
  );
};

export default App;
