import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { getAccountInformation } from '../../../services/user';
import { UserContext } from '../../../context/UserProvider';
import { styles } from '../../../config/defaultPageStyles';

const Loading = () => (
  <div
    style={{
      ...styles.pageContainer,
      minHeight: '100vh',
      height: 'auto',
      textAlign: 'center',
    }}>
    Loading...
  </div>
);

const Profile = ({ customer, user }) => (
  <div style={{ marginTop: '20px' }}>
    <div>
      <strong>Name: </strong> {customer['first-name'] ?? ''}{' '}
      {customer['last-name'] ?? ''}
    </div>
    <div>
      <strong>Email: </strong> {customer['email-address'] ?? ''}
    </div>
    <div>
      <strong>Phone: </strong> {user['login'] ?? ''}
    </div>
    <div>
      <strong>Address: </strong> {customer['address-line-1'] ?? ''}{' '}
      {customer['address-line-2'] ?? ''}
    </div>
    <div>
      <strong>City: </strong> {customer['address-city'] ?? ''}
    </div>
    <div>
      <strong>State / Province: </strong> {customer['address-province'] ?? ''}
    </div>
    <div>
      <strong>Zip Code: </strong> {customer['address-zip-code'] ?? ''}
    </div>
    <div>
      <strong>Birth Date: </strong> {customer['birth-date'] ?? ''}
    </div>
  </div>
);

const App = () => {
  const [data, setData] = useState(null);
  const { token } = useContext(UserContext);
  const navigate = useNavigate();

  const loadData = () => {
    getAccountInformation(token).then(response => {
      setData(response);
      console.log(response);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!data) {
    return <Loading />;
  }

  const { customer, user } = data;

  return (
    <div
      style={{ ...styles.pageContainer, minHeight: '100vh', height: 'auto' }}>
      <div style={styles.pageHeader}>My Profile</div>
      <Profile customer={customer} user={user} />
      <Button
        onClick={() => navigate(-1)}
        variant="contained"
        fullWidth
        style={{ marginTop: '20px', float: 'left', marginBottom: '30px' }}>
        BACK
      </Button>
      <Button
        onClick={() => {
          window.location.href = 'https://boxtrackcargo.com.ph/account-deletion';
        }}
        variant="outlined"
        color="error"
        fullWidth
        style={{ float: 'left', marginBottom: '30px' }}>
        Delete My Account
      </Button>
    </div>
  );
};

export default App;
