import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { accountBalance } from '../../../services/user';
import { moneyFormat } from '../../../utils/helpers';

const CustomerDashboardPage = () => {
  const { logout, token } = useContext(UserContext);
  const [accountCredit, setAccountCredit] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    loadAccountCredit();
  }, []);

  const loadAccountCredit = () => {
    accountBalance(token).then(res => {
      setAccountCredit(res.balance);
    });
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Grid container spacing={2} layout={'row'}>
      <Grid
        item
        xs={6}
        height={'10px'}
        style={{ fontWeight: 'bold', fontSize: '25px', color: '#8b8b8b' }}>
        Wallet:
      </Grid>
      <Grid
        item
        xs={6}
        height={'10px'}
        textAlign={'right'}
        style={{ fontWeight: 'bold', fontSize: '25px', color: '#8b8b8b' }}>
        PHP {moneyFormat(accountCredit || 0)}
      </Grid>
      <Grid item xs={12} height={'auto'}>
        <Link to="/driver/orders" style={{ width: '100%' }}>
          <Card className="card" style={{ boxShadow: 'none', height: 'auto' }}>
            <img src="/img/look-for-booking.png" style={{ width: '100%' }} />
          </Card>
        </Link>
        <Link to="/driver/history/orders" style={{ width: '100%' }}>
          <Card className="card" style={{ boxShadow: 'none', height: 'auto' }}>
            <img src="/img/booking-history.png" style={{ width: '100%' }} />
          </Card>
        </Link>
        <Link to="/driver/wallet" style={{ width: '100%' }}>
          <Card className="card" style={{ boxShadow: 'none', height: 'auto' }}>
            <img src="/img/my-wallet.png" style={{ width: '100%' }} />
          </Card>
        </Link>
      </Grid>
      <Grid item xs={12} height={'auto'}>
        <Button
          onClick={() => navigate('/driver/profile')}
          variant="contained"
          fullWidth
          style={{ marginTop: '5px' }}>
          My Profile
        </Button>
      </Grid>
      <Grid item xs={12} height={'auto'}>
        <Button onClick={() => handleLogout()} variant="outlined" fullWidth>
          Sign Out
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomerDashboardPage;
