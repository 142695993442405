import React, { useContext, useEffect } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { login } from '../../services/authentication';
import { UserContext } from '../../context/UserProvider.jsx';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Fields from '../../components/Fields';
import LoadingScreen from '../LoadingScreen/index.jsx';

const config = [
  {
    type: 'textfield',
    id: 'otp',
    label: 'One Time PIN',
  },
];
const schema = z.object({
  otp: z.coerce.string().min(5),
});

const OTPForm = ({ loginData, nextStep, prevStep, userType }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { register, control, handleSubmit, formState } = useForm({
    resolver: zodResolver(schema),
  });
  const { setToken, setUserType, setUserAttr } = useContext(UserContext);

  const onSubmit = data => {
    setIsLoading(true);
    login({
      otp: data.otp,
      login: loginData.login,
      password: loginData.password,
      type: userType,
      channel: loginData.channel,
    })
      .then(res => {
        if (res['access-token']) {
          setToken(res['access-token']);
          setUserType(userType);
          setUserAttr(JSON.stringify(res['user-attribute']));
          nextStep();
        } else {
          alert('Invalid code');
        }
        setIsLoading(false);
      })
      .catch(() => {
        alert('Invalid code');
        setIsLoading(false);
      });
  };

  useEffect(() => { 
    if (loginData?.accessToken) {
      setToken(loginData.accessToken);
      setUserType(loginData.type);
      setUserAttr(JSON.stringify([]));
      nextStep();
    }
  }, [loginData]);

  const { errors } = formState;
  return (
    <Grid container direction="column" alignItems="center">
      {isLoading && <LoadingScreen />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="center-container">
          <Typography variant="h4">Login</Typography> <br></br>
          <Fields
            config={config}
            errors={errors}
            register={register}
            control={control}
          ></Fields>
          <Button type="submit" variant="contained" className="action">
            Submit OTP
          </Button>
          <Button
            onClick={() => prevStep()}
            variant="contained"
            className="action"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Grid>
  );
};

export default OTPForm;
