import React, { useEffect, useContext, useState } from 'react';
import QRCode from 'react-qr-code';
import { showRegular as show } from '../../../services/order';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';

let regularLabels = {
  door2door: 'Door 2 Door',
  dropoff2dropoff: 'Dropoff 2 Dropoff',
  dropoff2door: 'Dropoff 2 Door',
  door2dropoff: 'Door 2 Dropoff',
};

const ShippingLabel = () => {
  const { id } = useParams();
  const { token } = useContext(UserContext);
  const [jobOrder, setJobOrder] = useState(null);
  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  useEffect(() => {
    show(token, id).then(res => {
      if (res.status) {
        return;
      }
      setJobOrder(res.data);
    });
  }, []);

  useEffect(() => {
    if (jobOrder) {
      handlePrint();
    }
  }, [jobOrder]);

  if (!jobOrder) {
    return null;
  }

  return (
    <>
      <div
        style={{
          width: '4.1in',
          height: '5.8in',
          border: '5px solid rgb(0 0 0)',
          margin: 'auto',
          backgroundImage: 'url(/img/waybill.png)',
          backgroundSize: 'cover',
        }}
      >
        <div
          style={{
            marginLeft: '175px',
            marginTop: '10px',
          }}>
          <QRCode
            value={`${jobOrder?.data?.verification}:${jobOrder?.data?.['reference-number']}:${id}`}
            size={90}
          />

          <div
            style={{
              fontSize: '10px',
              marginTop: '5px',
              float: 'right',
              width: '120px',
              textAlign: 'center',
              paddingTop: '30px',
              fontWeight: 'bold',
              fontSize: '20px',
            }}>
            {new Date().toLocaleDateString()}
          </div>
        </div>

        <div
          style={{
            width: '171px',
            float: 'left',
            paddingTop: '30px',
            fontSize: '10px',
            paddingLeft: '10px',
            height: '300px',
          }}>
          <div>
            <strong>REF #:</strong> {jobOrder?.data?.['reference-number']}
          </div>
          <div>
            <strong>Type:</strong> {regularLabels[jobOrder?.type] ?? ''}
          </div>
          <div>
            <strong>Weight:</strong> {jobOrder?.data?.package?.packageWeight} KG
          </div>
          <div>
            <strong>Payment Type:</strong>{' '}
            {jobOrder['payment-type'].toUpperCase()}
          </div>
          <div>
            <strong>Cash Collection:</strong> PHP{' '}
            {jobOrder?.data?.pricing?.totalCodAmount}{' '}
          </div>
        </div>

        <div
          style={{
            width: '171px',
            float: 'left',
            paddingTop: '30px',
            fontSize: '10px',
            paddingLeft: '10px',
            height: '120px',
          }}>
          <strong>SENDER INFORMATION:</strong>
          <div>{jobOrder?.data?.sender?.name}</div>
          <div>{jobOrder?.data?.sender?.contactNumber}</div>
          <div>
            {jobOrder?.data?.sender?.addressLine1}{' '}
            {jobOrder?.data?.sender?.addressLine2}{' '}
            {jobOrder?.data?.sender?.city} {jobOrder?.data?.sender?.province}{' '}
            {jobOrder?.data?.sender?.zipCode} PH{' '}
          </div>
        </div>

        <div
          style={{
            width: '171px',
            float: 'left',
            paddingTop: '30px',
            fontSize: '10px',
            paddingLeft: '10px',
            height: '120px',
          }}>
          <strong>RECEIVER INFORMATION:</strong>
          <div>{jobOrder?.data?.receiver?.name}</div>
          <div>{jobOrder?.data?.receiver?.contactNumber}</div>
          <div>
            {jobOrder?.data?.receiver?.addressLine1}{' '}
            {jobOrder?.data?.receiver?.addressLine2}{' '}
            {jobOrder?.data?.receiver?.city} {jobOrder?.data?.receiver?.province}{' '}
            {jobOrder?.data?.receiver?.zipCode} PH{' '}
          </div>
        </div>

        <div
          style={{
            width: '171px',
            float: 'left',
            paddingTop: '30px',
            fontSize: '10px',
            paddingLeft: '10px',
            height: '120px',
          }}>
          <strong>Package Description:</strong>
          <div>{jobOrder?.data?.package?.packageDescription}</div>
        </div>
      </div>
    </>
  );
};

export default ShippingLabel;
